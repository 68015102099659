
import { catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEventType, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HeaderService } from '../shared/header.service';
import * as moment from 'moment';
@Injectable()
export class ServiceManService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private cookieSvc: CookieService, private headerSrv: HeaderService) { }
    amp(query) {
        if (query.length) {
            return '&';
        } else {
            return ''
        };
    }
    GetCAPPLiceneseByCompanyId(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getcapplicenses`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetCAPPActivatedList(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('coId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/cappActivatedList`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    ActivateCAPPCode(activationCode: string, licenseId: number, allChannels: number, companyId: number, user: {id: number, name: string, email: string}) {
        const body = new FormData();
        body.append('activationCode', activationCode);
        body.append('LicenseId', licenseId.toString());
        body.append('allChannels', allChannels.toString());
        body.append('coId', companyId.toString());
        body.append('user', user.id.toString());
        return this.http.post(`${this.url}/serviceman/cappActivate`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeactivateCAPPCode(activeCAPPId: number, companyId: number) {
        const body = new FormData();
        body.append('id', activeCAPPId.toString());
        body.append('coId', companyId.toString());
        return this.http.post(`${this.url}/serviceman/cappDeactivate`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SelfServeSignups(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/selfservesignup`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    CreateUpdateSelfServePage(selfServeData) {
        const body = JSON.stringify(selfServeData);
        if (selfServeData.hasOwnProperty('id')) {
            return this.http.put(`${this.url}/serviceman/selfservepage`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        } else {
            return this.http.post(`${this.url}/serviceman/selfservepage`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }
    }
    SelfServeApproval(approvalData: { userId: number, isApprove: boolean, companyId: number, companyIdAssign: number, companyMVIdAssigned: number }) {
        const body = new FormData();
        body.append('id', approvalData.userId.toString());
        body.append('approve', approvalData.isApprove.toString());
        body.append('companyId', approvalData.companyId.toString());
        if (approvalData.isApprove) {
            body.append('companyIdAssign', approvalData.companyIdAssign.toString());
        }
        if (approvalData.isApprove && approvalData.companyMVIdAssigned) {
            body.append('companyMVIdAssigned', approvalData.companyMVIdAssigned.toString());
        }
        return this.http.post(`${this.url}/serviceman/selfserveapproval`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteSelfServePage(selfServeData) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({ id: selfServeData.id, companyId: selfServeData.companyId })
        };
        return this.http.delete(`${this.url}/serviceman/selfservepage`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetSelfServePages(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getselfservepage`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetServiceDuration() {
        return this.http.get(`${this.url}/serviceman/getServiceDuration`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetActiveContracts(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getactivecontracts`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetActiveLicenses(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getactivelicenses`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetActiveProducts(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getactiveproducts`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    suspendContract(contractId: number, isSuspended: boolean, companyId: number) {
        const body = new FormData();
        body.append('id', contractId.toString())
        body.append('suspended', isSuspended.toString())
        body.append('companyId', companyId.toString());
        return this.http.post(`${this.url}/serviceman/suspendedcontract`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCompanyStats(compId: number, selDates: any, contractId?: number) {
        const body = new FormData();
        if (contractId) {
            body.append('contractId', contractId.toString())
        }
        body.append('companyId', compId.toString())
        body.append('startDate', selDates.startDate.toString())
        body.append('endDate', selDates.endDate.toString());
        return this.http.post(`${this.url}/serviceman/getusercompanystat`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CompanyInactiveUsers(companyId: number){
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString())
        return this.http.get(`${this.url}/serviceman//inactive_users`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    getPushedStats(compId: number, selDates: any, contractId?: number) {
        const body = new FormData();
        if (contractId) {
            body.append('contractId', contractId.toString())
        }
        body.append('companyId', compId.toString())
        body.append('startDate', selDates.startDate.toString())
        body.append('endDate', selDates.endDate.toString());
        return this.http.post(`${this.url}/serviceman/getUsageSharing`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUsageFeed(selDates: { startDate: string, endDate: string }, companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('startDate', selDates.startDate.toString())
        Params = Params.append('endDate', selDates.endDate.toString());
        Params = Params.append('companyId', companyId.toString())
        return this.http.get(`${this.url}/serviceman/getUsageFeed`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetUsageNewsFeed(selDates: { startDate: string, endDate: string }, companyId: number, type: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', selDates.startDate.toString())
        Params = Params.append('endDate', selDates.endDate.toString());
        Params = Params.append('companyId', companyId.toString());
        Params = Params.append('type', type.toString());
        return this.http.get(`${this.url}/serviceman/getUsageNewsType`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    getAllUsers() {
        return this.http.get(`${this.url}/serviceman/getAllUsers`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    refreshUserSession(userId: number) {
        const body = new FormData();
        body.append('userId', userId.toString());
        return this.http.post(`${this.url}/serviceman/RefreshDashboardSession`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    deleteUserPermanently(userId: number) {
        // ⚠️ Warning for authorized user only ⚠️
        const body = new FormData();
        body.append('userId', userId.toString());
        return this.http.post(`${this.url}/serviceman/deleteUser`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        // ⚠️ Warning for authorized user only ⚠️
    }
    resendMarketReport(emailArray: any[], markertReportId: number, timeSlotId: number) {
        const body = new FormData();
        body.append('email', emailArray.join())
        body.append('id', markertReportId.toString())
        body.append('timeslot', timeSlotId.toString());
        return this.http.post(`${this.url}/serviceman/resendmarketreport`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ResendI2Report(archiveReport: { id: number, type: string, datePublished: string },
        subscribers: { company: string, email: string, first_name: string, id: number, last_name: string, name: string, title: string }[], addedInternalEmails: string[]) {
        const body = new FormData();
        body.append('archiveId', archiveReport.id.toString())
        body.append('archiveType', archiveReport.type.toString())
        const allSubscibersId = subscribers.map(sub => sub.id);
        body.append('subscribers', allSubscibersId.join());
        if (addedInternalEmails && addedInternalEmails.length) {
            body.append('internal', addedInternalEmails.join());
        }
        return this.http.post(`${this.url}/serviceman/resendi2report`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getI2ReportTime() {
        let Params = new HttpParams();
        Params = Params.append('type', 'array')
        return this.http.get(`${this.url}/serviceman/getI2ReportTime`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getI2ReportDayOfWeek() {
        let Params = new HttpParams();
        Params = Params.append('type', 'array')
        return this.http.get(`${this.url}/serviceman/getDayOfWeek`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    updateI2ReportTime(reportId: number, time: number) {
        const body = new FormData();
        body.append('id', reportId.toString())
        body.append('time', time.toString());
        return this.http.post(`${this.url}/serviceman/updatei2reporttime`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getReport(reportId: number, typeOfReport: string) {
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString())
        Params = Params.append('typeofreport', typeOfReport)
        return this.http.get(`${this.url}/serviceman/getReport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getReportPreview(reportId: number, type: string) {
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString())
        Params = Params.append('type', type)
        return this.http.get(`${this.url}/serviceman/getReportPublish`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    GetPublicReportLink(reportId: number, type: string) {
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString())
        Params = Params.append('type', type)
        return this.http.get(`${this.url}/serviceman/publicreport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    changeCompName(oldCompanyId: number, newCompanyMVId: number, compDescription: string) {
        const body = new FormData();
        body.append('companyId', oldCompanyId.toString())
        body.append('companyMVId', newCompanyMVId.toString())
        body.append('description', compDescription);
        return this.http.post(`${this.url}/serviceman/changeCompany`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    editReportsDeliveryTimes(userId) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId)
        return this.http.get(`${this.url}/serviceman/getDeliveryTime`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    saveReportsDeliveryTimes(licenseId: number, userId: number, deliverytime: number) {
        const body = new FormData();
        body.append('licenseId', licenseId.toString())
        body.append('userId', userId.toString())
        body.append('deliverytime', deliverytime.toString());
        return this.http.post(`${this.url}/serviceman/saveDeliveryTime`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaSearch(metatype, term) {
        let Params = new HttpParams();
        Params = Params.append('metatype', metatype);
        Params = Params.append('term', term);
        return this.http.get(`${this.url}/serviceman/getMetaSearch`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    getDataServiceSearch(term) {
        let Params = new HttpParams();
        Params = Params.append('term', term);
        return this.http.get(this.url + '/serviceman/getSeries',
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    getAssignUsers(licenseId) {
        let Params = new HttpParams();
        Params = Params.append('licenseId', licenseId)
        return this.http.get(`${this.url}/serviceman/getAssignUsers`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    saveAssignUsers(assignId: number, licenseId: number, companyMVIdOrName: any, addNew: boolean) {
        const body = new FormData();
        body.append('assignId', assignId.toString())
        body.append('licenseId', licenseId.toString())
        if (addNew) {
            body.append('newCompany', companyMVIdOrName);
        } else {
            body.append('companyId', companyMVIdOrName);
        }
        return this.http.post(`${this.url}/serviceman/assignUser`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    createAssignCompany(companyMVIdOrName: any, addNew: boolean) {
        const body = new FormData();
        if (addNew) {
            body.append('newCompany', companyMVIdOrName);
        } else {
            body.append('companyId', companyMVIdOrName);
        }
        return this.http.post(`${this.url}/serviceman/assignCompany`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    queryMetaValueCompanies(query) {
        const body = new FormData();
        body.append('q', query)
        return this.http.post(`${this.url}/serviceman/getcompanies2`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    batchAdjustSeats(companyId: number, seatsArray: any[]) {
        const body = JSON.stringify({ companyId: companyId, seatInfo: seatsArray });
        return this.http.post(`${this.url}/serviceman/batchadjustseat`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getBatchUsers(contractId: number, usersArray: any[]) {
        const body = new FormData();
        body.append('contractId', contractId.toString());
        body.append('userId', usersArray.join());
        return this.http.post(`${this.url}/serviceman/getbatchusers`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUserUsage(startDate, endDate, type, userId) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate)
        Params = Params.append('endDate', endDate)
        Params = Params.append('type', type)
        Params = Params.append('userId', userId)
        return this.http.get(
            this.url + '/serviceman/getUsageNewsType',
            { params: Params, headers: this.headerSrv.returnHeader() }
        ).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getUserUsageSharing(startDate, endDate, userId) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate)
        Params = Params.append('endDate', endDate)
        Params = Params.append('userId', userId)
        return this.http.get(`${this.url}/serviceman/getUsageSharing`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    uploadPdf(filename) {
        const body = new FormData();
        body.append('filename', filename)
        return this.http.post(this.url + '/serviceman/pdfupload', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    uploadBatchUsers(companyId: number, licenseId: number, batchUsers: any[]) {
        const body = JSON.stringify({ licenseId: licenseId, companyId: companyId, users: batchUsers });
        return this.http.post(this.url + '/serviceman/batchuserimport', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    submitBatchUsersToLic(companyId: number, contractId: number, batchUsersArray: any[]) {
        const body = JSON.stringify({ companyId: companyId, contractId: contractId, users: batchUsersArray });
        return this.http.post(`${this.url}/serviceman/submitbatchuserstolic`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    uploadBatchUsersToLic(companyId: number, batchUsers: any[], contractId?: number) {
        const body = JSON.stringify({ companyId: companyId, contractId: contractId, users: batchUsers });
        return this.http.post(this.url + '/serviceman/batchusertolicimport', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddUsersToDataSourceList(newDataSource: { title: string, users: any }) {
        const body = JSON.stringify({ dataSourceName: newDataSource.title, users: newDataSource.users });
        return this.http.post(`${this.url}/serviceman/batchusertoi2mailchimpimport`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    batchLicenseExport(companyId: number, contractsArray: any[], licensesArray: any[]) {
        const body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('contractId', contractsArray.join());
        body.append('licenseId', licensesArray.join());
        return this.http.post(this.url + '/serviceman/batchlicenseexport', body, {
            headers: this.headerSrv.returnHeader(),
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    addUsersToSubscription(usersArray: number[], licenseId: number, editMode: boolean) {
        const body = new FormData();
        body.append('userId', usersArray.join());
        body.append('licenseId', licenseId.toString());
        body.append('editMode', editMode.toString());
        return this.http.post(`${this.url}/serviceman/addsubscription`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    adjustLicenseSeats(seatCount: number, licenseId: number, companyId: number) {
        const body = new FormData();
        body.append('seatCount', seatCount.toString())
        body.append('companyId', companyId.toString())
        body.append('id', licenseId.toString());
        return this.http.post(this.url + '/serviceman/adjustseat', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getSearchSuggest(keyword, type) {
        let Params = new HttpParams();
        Params = Params.append('q', keyword)
        Params = Params.append('type', type)
        return this.http.get(`${this.url}/serviceman/searchsuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    removeUserSetting(userId: number, settingId: number, settingName: string, sectionId?: number) {
        const body = new FormData();
        body.append('userId', userId.toString())
        body.append('settingId', settingId.toString())
        body.append('name', settingName)
        if (typeof sectionId !== 'undefined') {
            body.append('sectionId', sectionId.toString())
        }
        return this.http.post(this.url + '/serviceman/removeSetting', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    updateaddAlert(userId: number, alertId: number, settingName: string, theValue: string, mode: string) {
        const body = new FormData();
        body.append('userId', userId.toString())
        if (alertId !== null) {
            body.append('alertId', alertId.toString())
        };
        body.append('name', settingName)
        body.append('theValue', theValue)
        body.append('mode', mode)
        return this.http.post(this.url + '/serviceman/updateAlert', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    updateaddUserSetting(userId: number, settingId: number, settingName: string, theValue: string, mode: string, sectionId?: number,
        chkDefault?: boolean) {
        const body = new FormData();
        body.append('userId', userId.toString())
        if (settingId !== null) {
            body.append('settingId', settingId.toString())
        };
        body.append('name', settingName)
        body.append('theValue', theValue)
        body.append('mode', mode)
        if (typeof sectionId !== 'undefined') {
            body.append('sectionId', sectionId.toString())
        }
        if (typeof chkDefault !== 'undefined') {
            body.append('chkDefault', chkDefault.toString())
        }
        return this.http.post(this.url + '/serviceman/updateSetting', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUserSetting(userId: number, name?: string) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString())
        if (typeof name !== 'undefined') {
            Params = Params.append('name', name)
        }
        return this.http.get(`${this.url}/serviceman/getusersettings`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getIntelParents() {
        return this.http.get(`${this.url}/serviceman/getIntelParents`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    addHistoryNote(companyId: number, userId: number, created2: number, change: string, note: string) {
        const body = new FormData();
        body.append('companyId', companyId.toString())
        body.append('userId', userId.toString())
        body.append('created2', created2.toString())
        body.append('change', change.toString());
        body.append('note', note)
        return this.http.post(`${this.url}/serviceman/addHistoryNote`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUsersHistories(userId: number, startDate: string, endDate: string) {
        const body = new FormData();
        body.append('userId', userId.toString())
        body.append('startDate', startDate)
        body.append('endDate', endDate)
        return this.http.post(this.url + '/serviceman/gethistories', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    recentViewed() {
        return this.http.get(this.url + '/serviceman/recentlyviewadd', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMoreSearchQuery(query: string, recset: string, total: number, pageSize: number) {
        let Params = new HttpParams();
        Params = Params.append('q', query)
        Params = Params.append('recset', recset)
        Params = Params.append('from', total.toString())
        Params = Params.append('size', pageSize.toString());
        return this.http.get(`${this.url}/serviceman/search`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getUserCompanySearch(query) {
        return this.http.get(this.url + '/serviceman/search?q=' + query, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getProducts() {
        return this.http.get(this.url + '/serviceman/getproducts', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getIndustries() {
        return this.http.get(this.url + '/serviceman/getindustries', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMarketreports() {
        return this.http.get(this.url + '/serviceman/getMarketReports', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getTimeslots() {
        return this.http.get(this.url + '/serviceman/gettimeslots', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCIOTemplates() {
        return this.http.get(`${this.url}/serviceman/getmyinteltemplates`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getAllMappedReports() {
        return this.http.get(`${this.url}/serviceman/mapreports`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCIOTimeslots() {
        return this.http.get(`${this.url}/serviceman/getCIOTimeslots`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllReportFrequencies() {
        return this.http.get(`${this.url}/serviceman/getreportfreq`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllRecurlyPlans() {
        return this.http.get(`${this.url}/serviceman//recurly_plan`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    getCOBranches() {
        return this.http.get(`${this.url}/serviceman/getCOBranches`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getIntelType() {
        return this.http.get(this.url + '/serviceman/getinteltype', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUnassignedReports() {
        return this.http.get(this.url + '/serviceman/getUnassignedReport', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUnassignedReportsCIO(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(this.url + '/serviceman/getUnassignedReportCIO', { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getRelatedTo(contractId) {
        return this.http.get(
            this.url + '/serviceman/getRelatedTo?contractId=' + contractId,
            { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    getLicenseSubscribers(licenseId) {
        return this.http.get(
            this.url + '/serviceman/getLicenseSubscribers?licenseId=' + licenseId,
            { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    getCompanyUsers(companyId) {
        return this.http.get(`${this.url}/serviceman/getCompanyUsers?companyId=${companyId}`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getContract(companyId, contractId) {
        let query = '';
        if (companyId !== null) {
            query = query + this.amp(query) + 'companyId=' + companyId;
        }
        if (contractId !== null) {
            query = query + this.amp(query) + 'contractId=' + contractId;
        }
        return this.http.get(this.url + '/serviceman/getContract?' + query, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    delSubscription(subId: number, licenseId: number) {
        const body = new FormData();
        body.append('id', subId.toString())
        body.append('licenseId', licenseId.toString())
        return this.http.post(`${this.url}/serviceman/delsubscription`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    terminateContract(endDate, internalnote, id, companyId) {
        const body = new FormData();
        body.append('id', id)
        body.append('companyId', companyId)
        body.append('endDate', endDate)
        body.append('internalnote', internalnote)
        return this.http.post(this.url + '/serviceman/terminatecontract', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    postContractNote(companyId, contractId, relatedTo, subscription, note, noteId) {
        const body = new FormData();
        body.append('companyId', companyId)
        body.append('contractId', contractId)
        body.append('relatedTo', relatedTo)
        body.append('subscription', subscription)
        body.append('note', note)
        body.append('noteId', noteId)
        return this.http.post(this.url + '/serviceman/contractnote', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    postContracts(description, startDate, endDate, autorenew, companyId, id, contractPDF, updateAll?, org_id?) {
        const body = new FormData();
        body.append('description', description)
        body.append('startDate', startDate)
        body.append('endDate', endDate)
        body.append('autorenew', autorenew)
        body.append('companyId', companyId)
        body.append('id', id)
        body.append('contractPDF', contractPDF)
        if (typeof updateAll !== 'undefined') {
            body.append('updateAll', updateAll)
        }
        if (typeof org_id !== 'undefined') {
            body.append('org_id', org_id)
        }
        return this.http.post(this.url + '/serviceman/contract', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    removeNote(id, companyId) {
        const body = new FormData();
        body.append('id', id)
        body.append('companyId', companyId)
        return this.http.post(this.url + '/serviceman/removenote', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    delLicenses(contractId, licenseId) {
        const body = new FormData();
        body.append('id', licenseId)
        body.append('contractId', contractId)
        return this.http.post(this.url + '/serviceman/dellicense', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    postLicenses(productId, expDate, openLicense, marketreport, industry, timeslot, apponly, description, seats,
        companyId, licenseId, contractId, i2report, i2reportPubDate, i2reporttime, i2reportdayofweek, _i2reportCIO, _i2reportCIOHasFeed, _intelparent,
        _ciotemplate, _cobranch, _freq, _i2reportStartPubDate, _timeslotCIO, _heading, _reportname, _cioStatus, _cioReportId, _hasfeed, _capp, _brandedonly, 
        _nocopyright, _oRead, productAdmin) {
        const body = new FormData();
        body.append('companyId', companyId)
        body.append('id', licenseId)
        body.append('contractId', contractId)
        body.append('productId', productId)
        if(productId === 72 || productId === 73){
            body.append('productAdmin', productAdmin.id.toString());
        }
        body.append('seatCount', seats)
        body.append('description', description)
        body.append('industry', industry)
        body.append('timeslot', timeslot)
        body.append('marketreport', marketreport)
        body.append('expDate', expDate)
        body.append('apponly', apponly)
        body.append('openLicense', openLicense)
        body.append('i2report', i2report)
        body.append('pDate', i2reportPubDate)
        body.append('pTime', i2reporttime)
        body.append('pDayOfWeek', i2reportdayofweek)
        body.append('i2reportCIO', _i2reportCIO)
        body.append('i2reportCIOHasFeed', _i2reportCIOHasFeed)
        body.append('intelparent', _intelparent)
        body.append('ciotemplate', _ciotemplate)
        body.append('cobranch', _cobranch)
        body.append('freq', _freq)
        body.append('i2reportStartPubDate', moment(_i2reportStartPubDate).format('YYYY-MM-DD'));
        body.append('timeslotCIO', _timeslotCIO)
        body.append('heading', _heading)
        body.append('reportname', _reportname)
        body.append('cioStatus', _cioStatus)
        body.append('cioReportId', _cioReportId)
        body.append('hasfeed', _hasfeed)
        body.append('capp', _capp)
        body.append('brandedonly', _brandedonly)
        body.append('nocopyright', _nocopyright)
        body.append('openRead', _oRead)
        return this.http.post(this.url + '/serviceman/license', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    MoveOrDuplicateLicense(company_id: number, license_id: number, contract_id: number, new_contract_id: number, isMove: boolean){
        const body = new FormData();
        body.append('company_id', company_id.toString());
        body.append('license_id', license_id.toString());
        body.append('contract_id', contract_id.toString());
        body.append('new_contract_id', new_contract_id.toString());
        body.append('isMove', isMove.toString());
        return this.http.post(`${this.url}/serviceman/license_cd`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    getCompanyContracts(recset, from, companyId, isActiveContract: string, search) {
        let query = '';
        if (recset !== null) {
            query = query + this.amp(query) + 'recset=' + recset;
        }
        if (from !== null) {
            query = query + this.amp(query) + 'from=' + from;
        }
        if (companyId !== null) {
            query = query + this.amp(query) + 'companyId=' + companyId;
        }
        if (isActiveContract !== null) {
            query = query + this.amp(query) + 'isActiveContract=' + isActiveContract;
        }
        if (search !== null) {
            query = query + this.amp(query) + 'search=' + encodeURI(search);
        }
        return this.http.get(this.url + '/serviceman/getCompanyContracts?' + query, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getCompanyHistories(companyId) {
        return this.http.get(
            this.url + '/serviceman/getCompanyHistories?companyId=' + companyId,
            { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler))
    }
    updateUserProfile(userinfo) {
        const body = new FormData();
        body.append('id', userinfo.id)
        body.append('companyId', userinfo.companyId)
        body.append('title', userinfo.title)
        body.append('firstName', userinfo.firstName)
        body.append('lastName', userinfo.lastName)
        body.append('password', userinfo.password)
        body.append('email', userinfo.email)
        body.append('phone', userinfo.phone)
        if(userinfo.hasOwnProperty('team_link')){
            body.append('team_link', userinfo.team_link);
        }
        if(userinfo.hasOwnProperty('division') && userinfo.division && Object.keys(userinfo.division).length > 0){
            body.append('division', userinfo.division.id.toString());
        }
        if(userinfo.hasOwnProperty('i2Titles') && userinfo.i2Titles){
            body.append('i2Titles', userinfo.i2Titles.id.toString());
        }
        if(userinfo.hasOwnProperty('i2AreaOfFocus') && userinfo.i2AreaOfFocus){
            body.append('i2AreaOfFocus', userinfo.i2AreaOfFocus.id.toString());
        }
        if(userinfo.hasOwnProperty('i2Relationship') && userinfo.i2Relationship.length){
            body.append('i2Relationship', userinfo.i2Relationship.map(i2Rel=>i2Rel.id).join());
        }
        return this.http.post(this.url + '/serviceman/profile', body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getCompaniesList() {
        return this.http.get(this.url + '/serviceman/getCompanies', { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getCompaniesDivisionsList(){
        return this.http.get(`${this.url}/serviceman/division`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUserProduct(uId: number) {
        return this.http.get(this.url + '/serviceman/userproduct/?userId=' + uId, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    forgotPassword(email) {
        let Params = new HttpParams();
        Params = Params.append('forgot', 'true')
        Params = Params.append('email', email);
        return this.http.get(this.url + '/serviceman/forgot', { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    addRemoveLicense(userId: number, lId: any[], sId: any[]) {
        const body = new FormData();
        body.append('userId', userId.toString())
        if (lId.length) {
            body.append('licenseId', lId.join())
        }
        if (sId.length) {
            body.append('subscriptionId', sId.join())
        }
        return this.http.post(`${this.url}/serviceman/addremovelicense`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    GetWelcomeBody(isTrailSite: boolean) {
        let Params = new HttpParams();
        if(isTrailSite){
            Params = Params.append('trialsite', 'true');
        }
        return this.http.get(`${this.url}/serviceman/getwelcomebody`, {  params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    getWelcomeSignatures() {
        return this.http.get(`${this.url}/serviceman/getwelcomesignatures`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    sendWelcomeEmail(sendEmailData) {
        const body = new FormData();
        body.append('wUserList', sendEmailData.wUserList.join())
        body.append('wPassList', sendEmailData.wPassList.join())
        body.append('body', sendEmailData.body)
        body.append('subject', sendEmailData.subject)
        body.append('sendUserId', sendEmailData.sendUserId)
        return this.http.post(`${this.url}/serviceman/sendwelcomeemail`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    sendBulkPassword(passArray) {
        const body = new FormData();
        body.append('wPassList', passArray.join())
        return this.http.post(`${this.url}/serviceman/sendwelcomeemail`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    SendWelcomePassword(userId: number) {
        const body = new FormData();
        body.append('userId', userId.toString())
        return this.http.post(`${this.url}/serviceman/sendwelcomeemail2`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getGreetingTime(m) {
        let g = null; // return g
        if (!m || !m.isValid()) { return; } // if we can't find a valid or filled moment, we return.
        const split_afternoon = 12 // 24hr time to split the afternoon
        const split_evening = 17 // 24hr time to split the evening
        const currentHour = parseFloat(m.format('HH'));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            g = 'afternoon';
        } else if (currentHour >= split_evening) {
            g = 'evening';
        } else {
            g = 'morning';
        }

        return g;
    }
    getIIStatLink(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/getusageurl`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getMostActive(rollback) {
        let Params = new HttpParams();
        Params = Params.append('rollback', rollback.toString());
        return this.http.get(this.url + '/serviceman/getMostActive', { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ToggleSuperUser(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/superuser`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPublishedCIOReports(startDate: moment.Moment, endDate: moment.Moment) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getpublishedreport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllDistributionLists(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/distributionlists`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetDistributionUsers(companyId: number, groupId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        Params = Params.append('groupId', groupId.toString());
        return this.http.get(`${this.url}/serviceman/distributionusers`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateDistributionList(companyId: number, groupId: number, currentUsers: { companyName: string, email: string, name: string, userId: number }[]) {
        const body = new FormData();
        if (currentUsers) {
            const currusersIdsArray = currentUsers.map(currentUser => currentUser.userId);
            body.append('userId', currusersIdsArray.join());
        }
        body.append('companyId', companyId.toString())
        body.append('groupId', groupId.toString())
        return this.http.post(`${this.url}/serviceman/duupdate`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    GetDistributionGroupOwners(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/dgowner`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetDistributionGroupUsage() {
        return this.http.get(`${this.url}/serviceman/dgusage`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddUpdateDistributionGroup(companyId: number, name: string, usageId: number, ownerId: number, act: string, id: number) {
        const body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('name', name.toString());
        body.append('usage', usageId.toString());
        body.append('owner', ownerId.toString());
        body.append('act', act.toString());
        if (id) {
            body.append('id', id.toString())
        }
        return this.http.post(`${this.url}/serviceman/dgupdate`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    ArrangeI2Modules(licenseId: number, i2ModulesIdArray: number[]) {
        let body = new FormData();
        body.append('licenseId', licenseId.toString());
        body.append('value', i2ModulesIdArray.join());
        return this.http.post(`${this.url}/serviceman/arrangemodule`, body,
            { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetCIOPending(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/getciopending`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateCIOPending(userId: number, actionData: any) {
        let actNum: number;
        actionData.action === 'approve' ? actNum = 2 : actNum = 5;
        const body = new FormData();
        body.append('id', actionData.id.toString());
        body.append('userId', userId.toString());
        body.append('act', actNum.toString());
        if (actNum === 2) {
            body.append('meta', actionData.selectedMetaValue.id.toString());
        }
        return this.http.post(`${this.url}/serviceman/updateciopending`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SetToPublic(reportId: number, typeofreport: string) {
        const body = new FormData();
        body.append('id', reportId.toString());
        body.append('typeofreport', typeofreport);
        return this.http.post(`${this.url}/serviceman/setToPublicReport`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RemoveFromPublic(reportId: number, typeofreport: string) {
        const body = new FormData();
        body.append('id', reportId.toString());
        body.append('typeofreport', typeofreport);
        return this.http.post(`${this.url}/serviceman/removePublicReport`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    OpenLicenseReports() {
        return this.http.get(`${this.url}/serviceman/reportOpenLicense`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GlobalReport(type: { id: string, name: string }, calDateRange: [Date, Date], rowPerPage: number) {
        let Params = new HttpParams();
        Params = Params.append('type', type.id);
        Params = Params.append('v', '2.0.0');
        if (calDateRange && calDateRange.length) {
            Params = Params.append('startDate', moment(calDateRange[0]).toISOString());
            Params = Params.append('endDate', moment(calDateRange[1]).toISOString());
        }
        Params = Params.append('rowPerPage', rowPerPage.toString());
        return this.http.get(`${this.url}/serviceman/globalreport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    async GlobalReportOptions() {
        return await this.http.get(`${this.url}/serviceman/globalreportoptions`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    LazyGlobalReport(type: { id: string, name: string }, calDateRange: [Date, Date], startRow: number, rowPerPage: number, rowNum: number, sortfield: string, sortOrder: number, searchRow: string, listKey: string) {
        let Params = new HttpParams();
        Params = Params.append('type', type.id)
        if (calDateRange && calDateRange.length) {
            Params = Params.append('startDate', moment(calDateRange[0]).toISOString());
            Params = Params.append('endDate', moment(calDateRange[1]).toISOString());
        }
        Params = Params.append('rowPerPage', rowPerPage.toString());
        Params = Params.append('rowNum', rowNum.toString());
        Params = Params.append('sortfield', sortfield);
        Params = Params.append('sortorder', sortOrder.toString());
        Params = Params.append('startRow', startRow.toString());
        if (searchRow) {
            Params = Params.append('searchRow', searchRow.toString());
        }
        Params = Params.append('listKey', listKey.toString());
        Params = Params.append('v', '2.0.0');
        return this.http.get(`${this.url}/serviceman/globalreport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    PrintGlobalReport(type: { id: string, name: string }, calDateRange: [Date, Date], sortfield: string) {
        let Params = new HttpParams();
        Params = Params.append('type', type.id)
        if (calDateRange && calDateRange.length) {
            Params = Params.append('startDate', moment(calDateRange[0]).toISOString());
            Params = Params.append('endDate', moment(calDateRange[1]).toISOString());
        }
        Params = Params.append('sortfield', sortfield);
        Params = Params.append('printcsv', 'true');
        return this.http.get(`${this.url}/serviceman/globalreport`, { params: Params, headers: this.headerSrv.returnHeader(), responseType: 'text' }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    async GetGlobalNotifications(contractId: number) {
        let Params = new HttpParams();
        if (contractId) {
            Params = Params.append('contractId', contractId.toString());
        }
        return await this.http.get(`${this.url}/serviceman/globalnotificationGet`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    PostGlobalNotifications(globalNotificationData: { message: string, title: string, degree: number, expiredDate: string, contractId: number }) {
        const body = new FormData();
        body.append('message', globalNotificationData.message);
        body.append('title', globalNotificationData.title);
        body.append('degree', globalNotificationData.degree.toString());
        body.append('expiredDate', globalNotificationData.expiredDate);
        if (globalNotificationData.contractId) {
            body.append('contractId', globalNotificationData.contractId.toString());
        }
        return this.http.post(`${this.url}/serviceman/globalnotificationPost`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteGlobalNotifications(globalNotificationData: { message: string, title: string, degree: number, expiredDate: string, id: number }) {
        const body = new FormData();
        body.append('id', globalNotificationData.id.toString());
        body.append('message', globalNotificationData.message);
        body.append('title', globalNotificationData.title);
        body.append('degree', globalNotificationData.degree.toString());
        body.append('expiredDate', globalNotificationData.expiredDate);
        body.append('delete', 'true');
        return this.http.post(`${this.url}/serviceman/globalnotificationPost`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MapUsersByCompanyAndContractId(companyId: number, contractId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        if (contractId) {
            Params = Params.append('contractId', contractId.toString());
        }
        return this.http.get(`${this.url}/serviceman/intelparentbycompany`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async GetRelationshipMapByUserId(userId: number, selectDiv?: number) {
        let Params = new HttpParams();
        Params = Params.append('user_id', userId.toString())
        Params = Params.append('level', '2');
        Params = Params.append('v', '2');
        Params = Params.append('weight', '100');
        if (selectDiv) {
            Params = Params.append('parentId', selectDiv.toString());
        }
        return await this.http.get(`${this.url}/serviceman/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    getMetaRelationshipById(parentId: number, selReciLevel: any, reciWeight: number, storyCountDays?: number) {
        let Params = new HttpParams();
        Params = Params.append('level', selReciLevel.toString())
        Params = Params.append('weight', reciWeight.toString())
        Params = Params.append('parentId', parentId.toString());
        if(storyCountDays){
            Params = Params.append('StoryDayCount', storyCountDays.toString());
        }
        return this.http.get(`${this.url}/serviceman/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    fullMapViewSearchSugguest(query: string) {
        let Params = new HttpParams();
        Params = Params.append('q', query);
        Params = Params.append('activeParent', 'true');
        return this.http.get(`${this.url}/serviceman/metasearchsuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostI2MapIntelParent(I2MapSelectedData: {
        SelectedUsers: { intelParent: { id: number, name: string }[], userId: number, title: string, isSuperUser: boolean, email: string, name: string }[],
        SelectedCompanyOrContract: { id: number, name: string, type: string }, SelectedAction: string, SelectedIntelParent: { id: number, value: string }
    }, companyId: number) {
        const body = new FormData();
        let SelectedUsersArray = [];
        if (I2MapSelectedData.SelectedUsers) {
            SelectedUsersArray = I2MapSelectedData.SelectedUsers.map(user => user.userId);
        }
        body.append('users', SelectedUsersArray.toString());
        body.append('companyId', companyId.toString());
        body.append('intelParentId', I2MapSelectedData.SelectedIntelParent.id.toString());
        body.append('process', I2MapSelectedData.SelectedAction);
        if (I2MapSelectedData.SelectedCompanyOrContract.type === 'contract') {
            body.append('contractId', I2MapSelectedData.SelectedCompanyOrContract.id.toString());
        }
        return this.http.post(`${this.url}/serviceman/intelparentbycompanyPost`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpPreviewDataSourceListById(dataSourceId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', dataSourceId.toString())
        return this.http.get(`${this.url}/serviceman/previewblastdsource`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpEmailStatusById(emailBlastId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', emailBlastId.toString())
        return this.http.get(`${this.url}/serviceman/blastemail`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpEmailStatusList() {
        return this.http.get(`${this.url}/serviceman/getBlastEmaiLStatus`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpAllEmailStatus() {
        return this.http.get(`${this.url}/serviceman/blastemail`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpDataSource() {
        return this.http.get(`${this.url}/serviceman/getblastdsource`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteI2MailChimpDataSourceById(dataSourceId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', dataSourceId.toString())
        return this.http.get(`${this.url}/serviceman/delblastdsource`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetI2MailChimpBlastBody() {
        return this.http.get(`${this.url}/serviceman/getblastbody`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostI2MailChimpFormData(addEditMailFormData: {
        id?: number, title: string, dataSource: { id: number, name: string }, emailFrom: string, emailSubject: string, body: string,
        status: { id: number, name: string }, dateSchedule: Date
    }) {
        const body = new FormData();
        if (addEditMailFormData.id) {
            body.append('id', addEditMailFormData.id.toString());
        }
        body.append('title', addEditMailFormData.title);
        body.append('dataSource', addEditMailFormData.dataSource.id.toString());
        body.append('emailFrom', addEditMailFormData.emailFrom);
        body.append('subject', addEditMailFormData.emailSubject);
        body.append('body', addEditMailFormData.body);
        body.append('status', addEditMailFormData.status.id.toString());
        body.append('schedulePublish', addEditMailFormData.dateSchedule.toISOString());
        return this.http.post(`${this.url}/serviceman/blastemailPost`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetGeolocationByIP(ipAddress: string) {
        let Params = new HttpParams();
        Params = Params.append('ipAddress', ipAddress)
        return this.http.get(`${this.url}/serviceman/getgeolocationbyip`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetCappModule(companyId: Number, LicenseId: Number, v2: Boolean) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString())
        Params = Params.append('LicenseId', LicenseId.toString()) 
        if ( v2 ) {
            return this.http.get(`${this.url}/serviceman/cappmodulesv2`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        } else {
            return this.http.get(`${this.url}/serviceman/cappmodules`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        };
    }
    UpdateCappModule(companyId: Number, moduleId: Number, moduleStatus: Boolean, LicenseId: Number, v2: Boolean) {
        const body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('moduleId', moduleId.toString());
        body.append('moduleStatus', moduleStatus.toString());
        body.append('LicenseId', LicenseId.toString()) 
        if ( v2 ) {
            return this.http.post(`${this.url}/serviceman/cappmodulesprocv2`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        } else {
            return this.http.post(`${this.url}/serviceman/cappmodulesproc`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        };
    }
    GetBotModules() {
        return this.http.get(`${this.url}/serviceman/getbotmodule`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotModulesById(moduleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', moduleId.toString());
        return this.http.get(`${this.url}/serviceman/getbotmodule`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    BotPreview(moduleId: number, toId: number, previewFreq: number) {
        const body = new FormData();
        body.append('id', moduleId.toString());
        body.append('to', toId.toString());
        if(previewFreq){
            body.append('freq', previewFreq.toString());
        }
        return this.http.post(`${this.url}/serviceman/botpreview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveTemplateLogo(licenseId: number, companyId: number, filename: string, url: string) {
        const body = new FormData();
        body.append('licenseId', licenseId.toString());
        body.append('companyId', companyId.toString());
        body.append('filename', filename);
        body.append('url', url);
        return this.http.post(`${this.url}/serviceman/savetemplatelogo`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveHeaderLogo(licenseId: number, companyId: number, id: string, url: string, link: string) {
        const body = new FormData();
        body.append('licenseId', licenseId.toString());
        body.append('companyId', companyId.toString());
        body.append('filename', id);
        body.append('url', url);
        if(link){
            body.append('link', link);
        }
        return this.http.post(`${this.url}/serviceman/saveheaderlogo`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteHeaderLogo(licenseId: number, companyId: number) {
        const body = new FormData();
        body.append('licenseId', licenseId.toString());
        body.append('companyId', companyId.toString());
        body.append('remove', 'true');
        return this.http.post(`${this.url}/serviceman/saveheaderlogo`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadImage(fileData: File){
        const body: FormData = new FormData();
        body.append('upload', fileData, fileData.name);
        return this.http.post(`${this.url}/serviceman/uploadtemplatelogo`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async DownloadPDFFile(pdfFileName: string) {
        const body = new FormData();
        body.append('pdffile', pdfFileName);
        return await this.http.post(`${this.url}/serviceman/pdfcontractdownload`, body, { headers: this.headerSrv.returnHeader(), responseType: 'blob' }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetBotAlertLicense(companyId: number, licenseId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        Params = Params.append('licenseId', licenseId.toString());
        return this.http.get(`${this.url}/serviceman/getBotAlertLic`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotUsers(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/serviceman/getbotuser`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotAlerts() {
        return this.http.get(`${this.url}/serviceman/getbotalert`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddBotAlertLicense(addLicenseData) {
        if(addLicenseData.hasOwnProperty('ContractName')){
            addLicenseData.ContractName
        }
        const body = JSON.stringify(addLicenseData);
        return this.http.post(`${this.url}/serviceman/saveBotAlertLic`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateBotAlertLicense(editLicenseData) {
        if(editLicenseData.hasOwnProperty('ContractName')){
            editLicenseData.ContractName
        }
        const body = JSON.stringify(editLicenseData);
        return this.http.put(`${this.url}/serviceman/saveBotAlertLic`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUserInbox(userId: number, dateSent: string){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        if(dateSent){
            Params = Params.append('date_sent', dateSent);
        }
        return this.http.get(`${this.url}/serviceman/inbox`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUserInboxItemById(userId: number, itemId: number){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        Params = Params.append('id', itemId.toString());
        return this.http.get(`${this.url}/serviceman/getsharestory`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageOpenReport(userId: number, startDate: string|Date, endDate: string|Date){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getUsageOpenReport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageOpenSharingReport(userId: number, startDate: string|Date, endDate: string|Date){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getUsageOpenSharingReport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUsageByUser(userId: number, startDate: string|Date, endDate: string|Date){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getusagebyuser`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUsageByContractId(contractId: number, startDate: string|Date, endDate: string|Date){
        let Params = new HttpParams();
        Params = Params.append('contractId', contractId.toString());
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getUsageByContract`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadBulkRemoveSubscribers(companyId: number, licenseId: number, excelFileData: File[]){
        const body: FormData = new FormData();
        excelFileData.forEach(file => {
            body.append('filedata', file, file.name);
        });
        body.append('companyId', companyId.toString());
        body.append('licenseId', licenseId.toString());
        return this.http.post(`${this.url}/serviceman/uploadremovesub`, body, { reportProgress: true, observe: 'events', headers: this.headerSrv.returnHeader() }).pipe(
            map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                  case HttpEventType.Response:
                    return event.body;
                  default:
                    return { status: 'progress', message: event.type };
                }
              }),
            catchError(this.headerSrv._errorHandler)
        );
    }
    GetGlobalClickers(startDate: string|Date, endDate: string|Date){
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate.toString());
        Params = Params.append('endDate', endDate.toString());
        return this.http.get(`${this.url}/serviceman/getglobalclicker`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAnalyticFeed(){
        return this.http.get(`${this.url}/serviceman/analyticfeed`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAnalyticFeedQuery(startDate: string, endDate: string){
        const body = new FormData();
        body.append('startdate', startDate);
        body.append('enddate', endDate);
        return this.http.post(`${this.url}/serviceman/analyticfeedqry`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPrePostAmble(licenseId: number, companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('licenseid', licenseId.toString());
        Params = Params.append('companyid', companyId.toString());
        return this.http.get(`${this.url}/serviceman/postpreamblem`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SavePrePostAmble(saveData, deleteproc) {
        const body = JSON.stringify(saveData);
        if ( saveData.id === null ) {
            return this.http.post(`${this.url}/serviceman/savepostpreamblem`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        } else {
            if ( deleteproc===true ) {
                const httpOptions = {
                    headers: this.headerSrv.returnHeader(),
                    body: JSON.stringify(saveData)
                };
                return this.http.delete(`${this.url}/serviceman/savepostpreamblem`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
            } else {
                return this.http.put(`${this.url}/serviceman/savepostpreamblem`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                    catchError(this.headerSrv._errorHandler));
            };
        };
    }
    GetAmblePreview(reportId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', reportId.toString());
        return this.http.get(`${this.url}/serviceman/previewprepostamble`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPrePostAmbleTemplate() {
        return this.http.get(`${this.url}/serviceman/prepostambletemplates`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUserSummary(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/usersummary`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetLoginFailed(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/loginfail`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GenerateNewPassword() {
        return this.http.get(`${this.url}/serviceman/autosuggestpassword`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    EditDeleteI2ConnectTemplate(action: string, data: {license_id: number, name: string, filename: string, id?: number}){
        const body:{license_id: number, name: string, filename?: string, id?: number } = { license_id: data.license_id, name: data.name };
        if(data.filename){
            body.filename = data.filename;
        }
        if(data.id){
            body.id = data.id;
            if(action === 'delete'){
                const httpOptions = {
                    headers: this.headerSrv.returnHeader(),
                    body: JSON.stringify(body)
                };
                return this.http.delete(`${this.url}/serviceman/i2connectlogo`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
            } else {
                return this.http.put(`${this.url}/serviceman/i2connectlogo`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                    catchError(this.headerSrv._errorHandler)); 
            }
        } else{
            return this.http.post(`${this.url}/serviceman/i2connectlogo`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }
        
    }
    GetI2ConnectPreview(logofile: string){
        const body = new FormData();
        body.append('logofile', logofile);
        return this.http.post(`${this.url}/serviceman/i2connectlogopreview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }

    UploadI2ConnectHeader(fileData: File[]) {
        const body: FormData = new FormData();
        fileData.forEach(file => {
            body.append('upload', file, file.name);
        });
        return this.http.post(`${this.url}/serviceman/uploadi2connectlogo`, body, { reportProgress: true, observe: 'events', headers: this.headerSrv.returnHeader() }).pipe(
            map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                  case HttpEventType.Response:
                    return event.body;
                  default:
                    return { status: 'progress', message: event.type };
                }
              }),
            catchError(this.headerSrv._errorHandler)
        );
    }
    CIOSchedularDates(startDate: string | Date, endDate: string | Date){
        const body = new FormData();
        body.append('startDate', startDate.toString())
        body.append('endDate', endDate.toString());
        return this.http.post(`${this.url}/serviceman/cioschedulercalendar`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CloneSchedular(sourceDate: string | Date, destinationDate: string | Date){
        const body = new FormData();
        body.append('sourceDate', sourceDate.toString())
        body.append('destinationDate', destinationDate.toString());
        return this.http.post(`${this.url}/serviceman/clonecioscheduler`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CIOScheduler(action: string, body: any){
        if(action === 'add'){
            return this.http.post(`${this.url}/serviceman/cioscheduler`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }else if(action === 'update'){
            return this.http.put(`${this.url}/serviceman/cioscheduler`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }else {
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(body)
            };
            return this.http.delete(`${this.url}/serviceman/cioscheduler`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        }
    }
    async PreviewPDF(html, viewPortWidthNumber: number, pageOrientation?: string) {
        let apiKey: string = 'api_public_5bc8453d1cae423bb7b26f240abcb722';
        let _headers = new HttpHeaders();
        _headers = _headers.append('Authorization', `Token: ${apiKey}`);
        _headers = _headers.append('Content-Type', 'application/json');
        let body = JSON.stringify({ htmlCode: html, viewportWidth: viewPortWidthNumber, pageOrientation: pageOrientation ? pageOrientation : 'auto' });
        return await this.http.post(` https://api.sejda.com/v2/html-pdf`, body, { headers: _headers, responseType: 'blob' }).toPromise();
    }
    UsageUserGraph(userId: number, startYear?: number, startWeek?: number) {
        let Params = new HttpParams();
        Params = Params.append('UserId', userId.toString());
        if(startYear){
            Params = Params.append('startYear', startYear.toString());
        }
        if(startWeek){
            Params = Params.append('startWeek', startWeek.toString());
        }
        return this.http.get(`${this.url}/serviceman/usageusergraph`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageUserAlert() {
        return this.http.get(`${this.url}/serviceman/usageuseralert`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageLicenseAlert() {
        return this.http.get(`${this.url}/serviceman/usagelicensealert?v=2`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageOveruseAlert() {
        return this.http.get(`${this.url}/serviceman/usageoveruse`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageLicenseAlertHide(companyId: number, contractId: number, licenseId: number, note: string){
        const body = new FormData();
        body.append('companyId', companyId.toString())
        body.append('contractId', contractId.toString());
        body.append('relatedto', licenseId.toString());
        body.append('note', note);
        return this.http.post(`${this.url}/serviceman/inactivelicensenote`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async SearchUserRoles(query: string) {
        let Params = new HttpParams();
        Params = Params.append('q', query)
        return await this.http.get(`${this.url}/serviceman/getuserfingerprint`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    async GetAllI2Titles(query: string){
        let Params = new HttpParams();
        if(query){
            Params = Params.append('q', query)
        }
        return this.http.get(`${this.url}/serviceman/getuseri2title`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)).toPromise();
    }
    async GetAllI2AreaOfFocus(query: string){
        let Params = new HttpParams();
        if(query){
            Params = Params.append('q', query)
        }
        return this.http.get(`${this.url}/serviceman/getuseri2areafocus`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)).toPromise();
    }
    async GetAllI2Relationship(query: string){
        let Params = new HttpParams();
        if(query){
            Params = Params.append('q', query)
        }
        return this.http.get(`${this.url}/serviceman/getuseri2relationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)).toPromise();
    }
    async GetUserByFingerPrint(iMV: {id: number, name: string}[],iAMV: {id: number, name: string}[],oMV: {id: number, name: string}[], rowPerPage: number, GetUserTicks: {id: string, name: string},GetMinScoreTick: number){
        let Params = new HttpParams();
        if(iMV&&iMV.length){
            Params = Params.append('iMV', iMV.map(mv=>mv.id).join());
        }
        if(iAMV&&iAMV.length){
            Params = Params.append('iAMV', iAMV.map(mv=>mv.id).join());
        }
        if(oMV&&oMV.length){
            Params = Params.append('oMV', oMV.map(mv=>mv.id).join());
        }
        Params = Params.append('activeUser', GetUserTicks.id?GetUserTicks.id:null);
        Params = Params.append('minScore', GetMinScoreTick?GetMinScoreTick.toString():null);
        Params = Params.append('rowPerPage', rowPerPage.toString());
        return await this.http.get(`${this.url}/serviceman/getuserbyfingerprint`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetUserByFingerPrintLazy(iMV: {id: number, name: string}[],iAMV: {id: number, name: string}[],oMV: {id: number, name: string}[], GetUserTicks: {id: string, name: string}, GetMinScoreTick:number, startRow: number, rowPerPage: number, rowNum: number, sortfield: string, sortOrder: number, searchRow: string, listKey: string, filters: any) {
        let Params = new HttpParams();
        if(iMV&&iMV.length){
            Params = Params.append('iMV', iMV.map(mv=>mv.id).join());
        }
        if(iAMV&&iAMV.length){
            Params = Params.append('iAMV', iAMV.map(mv=>mv.id).join());
        }
        if(oMV&&oMV.length){
            Params = Params.append('oMV', oMV.map(mv=>mv.id).join());
        }
        Params = Params.append('activeUser', GetUserTicks.id?GetUserTicks.id:null);
        Params = Params.append('minScore', GetMinScoreTick?GetMinScoreTick.toString():null);
        Params = Params.append('rowPerPage', rowPerPage.toString());
        Params = Params.append('rowNum', rowNum.toString());
        Params = Params.append('sortfield', sortfield);
        Params = Params.append('sortorder', sortOrder.toString());
        Params = Params.append('startRow', startRow.toString());
        if (searchRow) {
            Params = Params.append('searchRow', searchRow.toString());
        }
        Params = Params.append('listKey', listKey.toString());
        if (filters) {
            Params = Params.append('filters', JSON.stringify(filters));
        }
        return this.http.get(`${this.url}/serviceman/getuserbyfingerprint`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    async GetAllI2User(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query)
        return await this.http.get(`${this.url}/serviceman/getuseri2all`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    WeeklyExpiredContracts(){
        return this.http.get(`${this.url}/serviceman/weeklycontractexpired`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async SentCredentialHistory(userId: number){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return await this.http.get(`${this.url}/serviceman/getCredentialSentHist`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetUserNotes(userId: number) {
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/serviceman/getUserNotes`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditUserNotes(action: string, body: any){
        if(action === 'Add'){
            return this.http.post(`${this.url}/serviceman/UserNotes`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }else if(action === 'Update'){
            return this.http.put(`${this.url}/serviceman/UserNotes`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }else {
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(body)
            };
            return this.http.delete(`${this.url}/serviceman/UserNotes`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        }
    }
    GetCompanyReports(companyId: number){
    let Params = new HttpParams();
    Params = Params.append('companyId', companyId.toString());
    return this.http.get(`${this.url}/serviceman/getCompanyReports`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
        catchError(this.headerSrv._errorHandler));
    }
    GetAllClientLogos(){
    return this.http.get(`${this.url}/serviceman/clientlogos`, { headers: this.headerSrv.returnHeader() }).pipe(
        catchError(this.headerSrv._errorHandler));
    }
    SearchSMNotesTags(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/serviceman/search_tag`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));  
    }
    ShowSMNotesByTagId(tags: {id: string, weight: number, type: string, name: string}[],isClientInsight?:boolean){
        let Params = new HttpParams();
        Params = Params.append('tag', tags.map(t=>t.id).join());
        if(isClientInsight){
            Params = Params.append('ci', 'true');
        }
        return this.http.get(`${this.url}/serviceman/search_tag_notes`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));  
    }
    AddSMNote(note: string, tags:{id: string, weight: number, type: string, name: string}[],isClientInsight?:boolean){
        const body = new FormData();
        body.append('note', note);
        body.append('tag', tags.map(t=>t.id).join());
        if(isClientInsight){
            body.append('ci', 'true');
        }
        return this.http.post(`${this.url}/serviceman/tag_notes`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRecentNotes(){
        return this.http.get(`${this.url}/serviceman/recent_search_tag_notes`,{ headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetClient360PivotTable(){
        return this.http.get(`${this.url}/serviceman/pivot_table`,{ headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    LicenseGroupAndUngroup(companyId: number, contractId: number, mode: 'group'|'ungroup', selectedLicensesId: number[]){
        const body = new FormData();
        body.append('id', selectedLicensesId.join());
        body.append('companyId', companyId.toString());
        body.append('mode', mode);
        body.append('contractId', contractId.toString());
        return this.http.post(`${this.url}/serviceman/licenses_group_ungroup`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ContractsGroupAndUngroup(companyId: number, contractId: number, mode: 'group'|'ungroup', subContractsId: number[]){
        const body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('mode', mode);
        body.append('contractId', contractId.toString());
        if(subContractsId && subContractsId.length){
            body.append('subcontractId', subContractsId.join());
        }
        return this.http.post(`${this.url}/serviceman/contracts_group_ungroup`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GroupContractRenewal(companyId: number, contractId: number, exp_date: string){
        const body = new FormData();
        body.append('company_id', companyId.toString());
        body.append('exp_date', exp_date);
        body.append('contract_id', contractId.toString());
        return this.http.post(`${this.url}/serviceman/contract_group_renewal`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllMicrositesAdmin(){
        return this.http.get(`${this.url}/serviceman/microsite`, {headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)); 
    }
    GetAllMicrositesAdminById(id: number){
        let Params = new HttpParams();
        Params = Params.append('id', id.toString());
        return this.http.get(`${this.url}/serviceman/microsite`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)); 
    }
    PutMicrositesAdmin(formBody){
        return this.http.put(`${this.url}/serviceman/microsite`, JSON.stringify(formBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostMicrositesAdmin(formBody){
        return this.http.post(`${this.url}/serviceman/microsite`, JSON.stringify(formBody), { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    DeleteMicrositesAdmin(formBody) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({ id: formBody.id})
        };
        return this.http.delete(`${this.url}/serviceman/microsite`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetInitMicrositeUsers(PageNo: number, RowCount: number, Grouping: number){
        let Params = new HttpParams();
        Params = Params.append('PageNo', PageNo.toString());
        Params = Params.append('RowCount', RowCount.toString());
        Params = Params.append('Grouping', Grouping.toString());
        return this.http.get(`${this.url}/serviceman/microsite_users`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)); 
    }
    LazyMicrositeUsers(PageNo: number, RowCount: number, Grouping: number, Sort_Order?:number, Sort?:string, Name?:string, Email?:string) {
        let Params = new HttpParams();
        Params = Params.append('PageNo', PageNo.toString());
        Params = Params.append('RowCount', RowCount.toString());
        Params = Params.append('Grouping', Grouping.toString());
        if(Sort_Order){
            Params = Params.append('Sort_Order', Sort_Order.toString());  
        }
        if(Sort){
            Params = Params.append('Sort', Sort);  
        }
        if(Name){
            Params = Params.append('Name', Name);  
        }
        if(Email){
            Params = Params.append('Email', Email);  
        }
        return this.http.get(`${this.url}/serviceman/microsite_users`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    GetMicrositeReportPreview(Grouping: number, Email: string){
        let Params = new HttpParams();
        Params = Params.append('Email', Email);
        Params = Params.append('Grouping', Grouping.toString());
        return this.http.get(`${this.url}/serviceman/microsite_reports`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)); 
    }
}